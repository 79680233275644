import '@fontsource/roboto';
import { DarkMode } from './components/dark-mode';
import { I18n, Locales } from './components/i18n';
import { Home } from './pages/home';
import { themes } from './themes'

const locales: Locales = [
  { id: 'en', label: 'English' },
  { id: 'fr', label: 'Français' },
];

export function App() {
  return (
    <DarkMode darkTheme={themes.dark} lightTheme={themes.light}>
      <I18n locales={locales}>
          <Home />
      </I18n>
    </DarkMode>
  );
}