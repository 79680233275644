import { Box, BoxProps, Button, Typography } from '@mui/material';
import ctaImage from './cta.jpg';

const i18n: { [key: string]: { [key: string]: string } } = {
    en: {
        title: 'Make sure you have the right solution for your needs',
        inquire: 'Get in touch with us',
    },
    fr: {
        title: 'Assurez-vous d\'avoir la bonne solution pour vos besoins',
        inquire: 'Entrez en contact',
    },
};
export function CTA(props: { locale: string, onClick: () => void, small: boolean } & BoxProps) {
    const [locale, onClick, small] = [props.locale, props.onClick, props.small];

    const t = (key: string): string => i18n[locale][key] || '';

    return (
        <Box my={4} {...props}>
            <Box sx={{ backgroundImage: 'url(' + ctaImage + ')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <Box alignItems="center" boxSizing="border-box" display="flex" flexDirection="row" flexWrap="wrap" margin="auto" maxWidth="lg" padding="4rem 2rem" width="100%">
                    <Box alignItems="center" display="flex" flex={small ? '100%' : '70%'} flexDirection="row" justifyContent="start">
                        <Typography color="common.white" textAlign={small ? 'center' : 'start'} variant="h4">
                            {t('title')}
                        </Typography>
                    </Box>
                    <Box alignItems="center" display="flex" flex={small ? '100%' : '30%'} flexDirection="row" justifyContent="center">
                        <Button color="secondary" variant="contained" onClick={onClick} sx={{ margin: '2rem auto 0' }}>
                            {t('inquire')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}