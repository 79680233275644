import { useState } from 'react';
import { Box, BoxProps, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Policy } from './policy';
import { Terms } from './terms';

const business = {
    city: 'Blainville',
    country: 'Canada',
    email: 'admin@saubeo.solutions',
    name: 'Saubeo Solutions',
    phone: '+1 514 316-9050',
    street: '16 Florins st.',
    state: 'Quebec',
    url: 'https://saubeo.solutions',
    zip: 'J7C 5P6',
};

const i18n: { [key: string]: { [key: string]: any } } = {
    en: {
        allRightsReserved: 'All rights reserved',
        close: 'Close',
        privacyPolicy: 'Privacy policy',
        termsOfUse: 'Terms of use',
    },
    fr: {
        allRightsReserved: 'Tous droits réservés',
        close: 'Fermer',
        privacyPolicy: 'Politique de confidentialité',
        termsOfUse: 'Conditions d\'utilisation',
    },
};

export function Footer(props: { locale: string, small: boolean } & BoxProps) {
    const [locale, small] = [props.locale, props.small];

    const [policy, setPolicy] = useState(false);
    const [terms, setTerms] = useState(false);

    const t = (key: string) => i18n[locale][key] || '';

    return (
        <Box color="common.white" padding="4rem 2rem" sx={{ backgroundColor: 'grey.900' }} {...props}>
            <Box margin="auto" maxWidth="lg" width="100%">
                <Box alignItems="center" display="flex" flexDirection="row" flexWrap="wrap" gap="2rem" justifyContent="space-between">
                    <Typography variant="body2">
                        &copy; 2012 - {new Date().getFullYear()} {business.name}. {t('allRightsReserved')}.
                    </Typography>
                    <Box display="flex" flexDirection="row" gap="2rem" justifyContent="space-between">
                        <Button color="inherit" onClick={() => setPolicy(true)} size="small" variant="text">
                            {t('privacyPolicy')}
                        </Button>
                        <Button color="inherit" onClick={() => setTerms(true)} size="small" variant="text">
                            {t('termsOfUse')}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Dialog disableEscapeKeyDown={true} fullScreen={small} maxWidth="lg" open={policy}>
                <DialogContent>
                    <Policy
                        businessCity={business.city}
                        businessCountry={business.country}
                        businessName={business.name}
                        businessPhone={business.phone}
                        businessStreet={business.street}
                        businessState={business.state}
                        businessUrl={business.url}
                        businessZip={business.zip}
                        locale={locale}
                    />
                </DialogContent>
                <DialogActions>
                    <Box m={1}>
                        <Button color="inherit" onClick={() => setPolicy(false)} variant="contained">
                            {t('close')}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown={true} fullScreen={small} maxWidth="lg" open={terms}>
                <DialogContent>
                    <Terms
                        businessCountry={business.country}
                        businessEmail={business.email}
                        businessName={business.name}
                        locale={locale}
                    />
                </DialogContent>
                <DialogActions>
                    <Box m={1}>
                        <Button color="inherit" onClick={() => setTerms(false)} variant="contained">
                            {t('close')}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}