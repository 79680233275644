import { Button, Divider } from '@mui/material';
import { I18nContext } from './i18n';

export function I18nChooser() {
    return (
        <I18nContext.Consumer>
            {(i18n) => (
                <ul style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    listStyleType: 'none',
                    margin: 0,
                    padding: 0,
                }}>
                    {i18n?.locales.map((loc, i) => (
                        <li key={i} style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <Button
                                disabled={loc === i18n.locale}
                                onClick={() => i18n?.setLocale(loc)}
                                size="small"
                                sx={{
                                    minWidth: 40,
                                    padding: 0,
                                }}
                                variant="text">
                                {loc.id}
                            </Button>
                            {
                                i + 1 < (i18n?.locales.length || 0) &&
                                <Divider flexItem={true} orientation="vertical" />
                            }
                        </li>
                    ))}
                </ul>
            )}
        </I18nContext.Consumer >
    );
}