import { useContext, useRef } from 'react';
import { Box, Fab, Fade } from '@mui/material';
import { KeyboardArrowUp as TopIcon } from '@mui/icons-material';
import { I18nContext } from '../../components/i18n';
import { Resizer, ResizerContext } from '../../components/resizer';
import { Scroller, ScrollerContext } from '../../components/scroller';
import { About } from './about';
import { CTA } from './cta';
import { Contact } from './contact';
import { Footer } from './footer';
import { Header } from './header';
import { Landing } from './landing';
import { Projects } from './projects';
import { Services } from './services';

export function Home() {
  const i18n = useContext(I18nContext);

  const ref = useRef<HTMLElement>();

  const scrollTo = (id: string): void => {
    const el = ref.current?.querySelector(`#${id}`)
    requestAnimationFrame(() => el?.scrollIntoView({ behavior: 'smooth' }));
  };

  return i18n !== undefined
    ? (
      <Resizer>
        <ResizerContext.Consumer>
          {(dimension) => {
            const small = dimension.height <= 720 || dimension.width <= 720;

            const scrollPadding = small ? null : {
              '&::before': {
                content: '""',
                display: 'block',
                marginTop: '-110px',
                paddingTop: '110px',
              }
            };

            return (
              <Scroller>
                <ScrollerContext.Consumer>
                  {(scroll) => {
                    const top = scroll.y < 128;

                    return (
                      <Box ref={ref} sx={{
                        backgroundColor: 'background.paper',
                        color: 'text.primary',
                        transition: 'all 425ms',
                      }}>
                        <Box id="top" />
                        <Header id="header" locale={i18n!.id} small={small} top={top} onMenu={(id: string) => scrollTo(id)} />
                        <Landing id="landing" locale={i18n!.id} small={small} />
                        <About id="about" locale={i18n!.id} small={small} sx={scrollPadding} />
                        <Services id="services" locale={i18n!.id} small={small} sx={scrollPadding} />
                        <CTA locale={i18n!.id} small={small} onClick={() => scrollTo('contact')} />
                        <Projects id="projects" locale={i18n!.id} small={small} sx={scrollPadding} />
                        <Contact id="contact" locale={i18n!.id} small={small} />
                        <Footer locale={i18n!.id} small={small} />
                        <Box sx={{ bottom: '1.5rem', opacity: 0.5, position: 'fixed', right: '1.5rem' }}>
                          <Fade in={small && !top}>
                            <Fab variant="circular" size="medium" onClick={() => scrollTo('top')}>
                              <TopIcon />
                            </Fab>
                          </Fade>
                        </Box>
                      </Box>
                    );
                  }}
                </ScrollerContext.Consumer>
              </Scroller>
            );
          }}
        </ResizerContext.Consumer>
      </Resizer>
    )
    : null
}