import { useState } from 'react';
import { Box, BoxProps, Button, Dialog, Typography } from "@mui/material";
import { ReactComponent as AppStoreIconEn } from './app-store-en.svg';
import { ReactComponent as AppStoreIconFr } from './app-store-fr.svg';
import { ReactComponent as GithubIconEn } from './github-en.svg';
import { ReactComponent as GithubIconFr } from './github-fr.svg';
import { ReactComponent as GooglePlayIconEn } from './google-play-en.svg';
import { ReactComponent as GooglePlayIconFr } from './google-play-fr.svg';
import rdioScannerImage from './rdio-scanner.png';

const i18n: { [key: string]: { [key: string]: string } } = {
    en: {
        closeDemo: 'Close the demo',
        getClient: 'Get the native client',
        getServer1: 'Get the server',
        getServer2: 'with its integrated webapp',
        text1: 'Known as the perfect software-defined radio companion, Rdio Scanner is an open source software that ingest and distribute audio files generated by various software-defined radio recorders. Its interface tries to reproduce the user experience of a real police scanner, while adding its own touch.',
        text2: 'Easy to use, it allows you to choose which talkgroups you want to listen to. You can replay or skip calls or even go back and listen to anything that was recorded previously. You can listen anywhere with internet access and unlike a traditional scanner, you don\'t miss a thing if there are multiple calls at the same time.',
        title: 'Rdio Scanner',
        tryDemo: 'Try the demo here',
    },
    fr: {
        closeDemo: 'Fermer la démo',
        getClient: 'Obtenez le client natif',
        getServer1: 'Obtenez le serveur',
        getServer2: 'avec le webapp intégrée',
        text1: 'Reconnu comme le parfait compagnon radio défini par logiciel, Rdio Scanner est un logiciel open source qui intègre et distribue des fichiers audio générés par divers enregistreurs radio définis par logiciel. Son interface tente de reproduire l\'expérience utilisateur d\'un vrai scanner de police, tout en y ajoutant sa touche personnelle.',
        text2: 'Facile à utiliser, il vous permet de choisir les groupes de discussion que vous souhaitez écouter. Vous pouvez rejouer ou sauter des appels ou même revenir en arrière et écouter tout ce qui a été enregistré précédemment. Vous pouvez écouter n\'importe où avec accès Internet et contrairement à un scanner traditionnel, vous ne manquez rien s\'il y a plusieurs appels en même temps.',
        subTitle: 'Le parfait compagnon à la radio défini par logiciel',
        title: 'Rdio Scanner',
        tryDemo: 'Essayer la démo ici',
    },
};

export function RdioScanner(props: { locale: string, small: boolean } & BoxProps) {
    const [locale, small] = [props.locale, props.small];

    const [demo, setDemo] = useState<boolean>(false);

    const t = (key: string): string => i18n[locale][key] || '';

    return (
        <Box {...props}>
            <Typography color="secondary" my={4} textAlign={small ? 'center' : 'start'} variant="h4">
                {t('title')}
            </Typography>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap="16px" my={2}>
                <Box flex={small ? '100%' : 'calc(100% - 16px - 350px)'}>
                    <Typography align="justify" variant="body1" mb={2}>
                        {t('text1')}
                    </Typography>
                    <Typography align="justify" variant="body1" mb={2}>
                        {t('text2')}
                    </Typography>
                </Box>
                <Box alignItems="center" display="flex" flex={small ? '100%' : '350px'} flexDirection="row" justifyContent="center">
                    <img src={rdioScannerImage} alt="Rdio Scanner" style={{ maxWidth: '100%' }} />
                </Box>
            </Box>
            <Box>
                <Box alignItems={small ? 'center' : 'start'} display="flex" flexDirection="column" mb="2rem">
                    <Button color="secondary" onClick={() => setDemo(true)} variant="contained">
                        {t('tryDemo')}
                    </Button>
                    <RdioScannerDemo locale={locale} onClose={() => setDemo(false)} open={demo} small={small} />
                </Box>
                <Box alignItems="flex-end" display="flex" flexDirection="row" flexWrap="wrap" gap={small ? 4 : 8} justifyContent={small ? 'center' : 'start'} mb={2}>
                    <Box alignItems={small ? 'center' : 'start'} display="flex" flexDirection="column">
                        <Typography align={small ? 'center' : 'justify'} mb={1} variant="caption">
                            {t('getServer1')}<br />{t('getServer2')}
                        </Typography>
                        <Button href="https://github.com/chuot/rdio-scanner/" sx={{ p: 0 }} target="_blank">
                            {locale === 'en' ? <GithubIconEn /> : locale === 'fr' ? <GithubIconFr /> : 'GitHub'}
                        </Button>
                    </Box>
                    <Box alignItems={small ? 'center' : 'start'} display="flex" flexDirection="column">
                        <Typography align={small ? 'center' : 'justify'} mb={1} variant="caption">
                            {t('getClient')}
                        </Typography>
                        <Box display="flex" gap={1}>
                            <Button color="inherit" href="https://apps.apple.com/us/app/rdio-scanner/id1563065667#?platform=iphone" sx={{ p: 0 }} target="_blank">
                                {locale === 'en' ? <AppStoreIconEn /> : locale === 'fr' ? <AppStoreIconFr /> : 'App Store'}
                            </Button>
                            <Button color="inherit" href="https://play.google.com/store/apps/details?id=solutions.saubeo.rdioScanner" sx={{ p: 0 }} target="_blank">
                                {locale === 'en' ? <GooglePlayIconEn /> : locale === 'fr' ? <GooglePlayIconFr /> : 'Google Play'}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function RdioScannerDemo({ locale, onClose, open, small }: {
    locale: string,
    onClose: () => void,
    open: boolean,
    small: boolean,
}) {
    const close = () => {
        clearTimeout(timer);
        onClose();
    }

    const t = (key: string): string => i18n[locale][key] || '';

    const timer = setTimeout(() => onClose(), 15 * 60 * 1000);

    return (
        <Dialog
            disableEscapeKeyDown={true}
            fullScreen={small}
            open={open}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: small ? null : '2rem',
                },
            }}
        >
            <Box display="flex" flexDirection="column" height="100%" sx={{
                backgroundColor: '#1e1e1e',
                color: '#fff',
            }}>
                <iframe
                    height="100%"
                    src="/demo/rdio-scanner"
                    style={{
                        border: 0,
                        flex: 1,
                        minHeight: '720px',
                        minWidth: '420px',
                    }}
                    title="Rdio Scanner demo"
                    width="100%"
                />
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Button color="inherit" onClick={() => close()} variant="text">
                        {t('closeDemo')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}