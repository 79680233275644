import { Box, BoxProps, List, ListItem, Typography } from '@mui/material';
import { Bullet } from './bullet';

const i18n: { [key: string]: { [key: string]: string } } = {
    en: {
        title: 'What we do',
        text: 'We provide software development services to businesses to help them make their workflows more efficient and streamlined. We deliver an exceptional customer experience by providing high quality and affordable custom application development services.',
        bullet1: 'Systems of any scale and complexity',
        bullet2: 'Development of complete custom applications',
        bullet4: 'Mobile-friendly web applications',
        bullet5: 'Native mobile applications',
        bullet6: 'Custom CMS, CRM systems',
        bullet7: 'UI / UX design',
        bullet8: 'Internet of things',
        bullet9: 'Prototyping and proof-of-concept',
    },
    fr: {
        title: 'Ce que nous faisons',
        text: 'Nous fournissons des services de développement de logiciels aux entreprises pour les aider à rendre leurs flux de travail plus efficaces et rationalisés. Nous offrons une expérience client exceptionnelle en fournissant des services de développement d\'applications personnalisées de haute qualité et abordables.',
        bullet1: 'Systèmes de toute échelle et complexité',
        bullet2: 'Développement d\'applications personnalisées complètes',
        bullet4: 'Applications Web adaptées aux mobiles',
        bullet5: 'Applications mobiles natives',
        bullet6: 'CMS personnalisé et systèmes CRM',
        bullet7: 'Conception UI / UX',
        bullet8: 'Internet des objets',
        bullet9: 'Prototypage et preuve de concept',
    },
};

export function Services(props: { locale: string, small: boolean } & BoxProps) {
    const [locale, small] = [props.locale, props.small];

    const t = (key: string): string => i18n[locale][key] || '';

    return (
        <Box margin="auto" maxWidth="lg" width="100%" {...props}>
            <Box m={2}>
                <Typography color="primary.dark" my={4} textAlign={small ? 'center' : 'start'} variant="h3">
                    {t('title')}
                </Typography>
                <Typography align="justify" variant="body1" sx={{ mb: 2 }}>
                    {t('text')}
                </Typography>
                <List sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {Object.keys(i18n[locale]).filter((key) => key.startsWith('bullet')).map((key, index) => (
                        <ListItem key={index} sx={{ flex: small ? '100%' : '50%', gap: 1 }}>
                            <Box>
                                <Bullet />
                            </Box>
                            <Typography variant="body1">
                                {t(key)}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );
}