import { createContext, ReactNode, useMemo, useState } from 'react';
import { Theme, ThemeProvider } from '@mui/material';

const darkModeKey = 'darkMode';

export interface DarkModeContextData {
    active: boolean;
    toggle: () => void;
}

export const DarkModeContext = createContext<DarkModeContextData | undefined>(undefined);

export function DarkMode({
    children,
    darkTheme,
    lightTheme,
}: {
    children: ReactNode,
    darkTheme: Theme,
    lightTheme: Theme,
}) {
    const [active, setDarkMode] = useState<boolean>(localStorage.getItem(darkModeKey) === 'true');

    const mode = useMemo<DarkModeContextData>(
        () => ({
            active: active,
            toggle: () => {
                setDarkMode((active) => !active);
                localStorage.setItem(darkModeKey, `${active}`);
            },
        }),
        [active],
    );

    const theme = useMemo<Theme>(() => active ? darkTheme : lightTheme, [active, darkTheme, lightTheme]);

    localStorage.setItem(darkModeKey, `${active}`);

    return (
        <DarkModeContext.Provider value={mode}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </DarkModeContext.Provider>
    );
}