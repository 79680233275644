import { createTheme, responsiveFontSizes } from '@mui/material';
import { grey, lightBlue, orange } from '@mui/material/colors';

export const darkTheme = responsiveFontSizes(createTheme({
  palette: {
    background: {
      default: grey[900],
      paper: '#29241e',
    },
    mode: 'dark',
    primary: Object.assign({}, lightBlue, { main: lightBlue['A700'] }),
    secondary: Object.assign({}, orange, { main: orange['A700'] }),
    // primary: lightBlue,
    // secondary: orange,
  },
  typography: {
    htmlFontSize: 14,
  },
}));