import { createContext, ReactNode, useEffect, useState } from 'react';

export interface ResizerContextData {
  height: number;
  width: number;
}

function getDimension(): ResizerContextData {
  return {
    height: window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight,
    width: window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth,
  };
}

export const ResizerContext = createContext<ResizerContextData>(getDimension());

export function Resizer({ children }: { children: ReactNode }) {
  const [dimension, setDimension] = useState(getDimension());

  useEffect(() => {
    const resizeListener = () => setDimension(getDimension());

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  return (
    <ResizerContext.Provider value={dimension}>
      {children}
    </ResizerContext.Provider>
  );
}