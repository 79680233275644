import { Box, BoxProps, Typography } from '@mui/material';
import aboutImage from './about.png';

const i18n: { [key: string]: { [key: string]: string } } = {
    en: {
        title: 'Who are we',
        text1: 'We started our activities in 2012 as an information technology consulting company, but have migrated our activities to the development of applications which is now our core business.',
        text2: 'With all the experience accumulated over the years, we offer software design services aligned with the objectives of our customers. Our ability to understand the problems of our customers combined with our original and varied ideas guarantee that our solutions meet the expectations of our customers.',
        text3: 'With our passion and the necessary technology, we are proud to be able to participate in the success of our customers.',
    },
    fr: {
        title: 'Qui sommes-nous',
        text1: 'Nous avons démarré nos activités en 2012 en tant que société de conseil en technologies de l\'information, mais avons migré nos activités vers le développement d\'applications qui est désormais notre cœur de métier.',
        text2: 'Forts de toute l\'expérience accumulée au fil des ans, nous offrons des services de conception de logiciels alignés avec les objectifs de nos clients. Notre capacité à comprendre les problématiques de nos clients alliée à nos idées originales et variées garantissent que nos solutions répondent aux attentes de nos clients.',
        text3: 'Avec notre passion et la technologie nécessaire, nous sommes fiers de pouvoir participer au succès de nos clients.',
    },
};

export function About(props: { locale: string, small: boolean } & BoxProps) {
    const [locale, small] = [props.locale, props.small];

    const t = (key: string): string => i18n[locale][key] || '';

    return (
        <Box margin="auto" maxWidth="lg" width="100%" {...props}>
            <Box m={2}>
                <Typography color="primary.dark" my={4} textAlign={small ? 'center' : 'start'} variant="h3">
                    {t('title')}
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap="32px" justifyContent="center">
                    <Box flex={small ? '100%' : '0 0 calc(100% - 300px - 32px)'}>
                        <Typography align="justify" mb={2} variant="body1">
                            {t('text1')}
                        </Typography>
                        <Typography align="justify" mb={2} variant="body1">
                            {t('text2')}
                        </Typography>
                        <Typography align="justify" mb={2} variant="body1">
                            {t('text3')}
                        </Typography>
                    </Box>
                    <Box display="flex" flex={small ? '100%' : '0 0 300px'} flexDirection="row" justifyContent="center">
                        <img src={aboutImage} alt='elegant javascript tie' style={{ maxWidth: '100%' }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}