import { SvgIcon, useTheme } from '@mui/material';
import { ReactComponent as BulletImage } from './bullet.svg';

export function Bullet() {
    const theme = useTheme();

    return <SvgIcon component={BulletImage} sx={{
        '& :nth-of-type(1)': { display: 'none' },
        '& :nth-of-type(2)': { fill: theme.palette.secondary.main },
    }} />
}