import { useState } from 'react';
import { AppBar, Box, Button, createTheme, IconButton, Menu, MenuItem, ThemeProvider, useTheme } from '@mui/material';
import { Close as CloseIcon, Menu as OpenIcon } from '@mui/icons-material';
import { DarkModeSwitch } from '../../components/dark-mode';
import { I18nChooser } from '../../components/i18n';
import { Logo } from '../../components/logo';

const i18n: { [key: string]: { [key: string]: string } } = {
    en: {
        about: 'About',
        contact: 'Contact',
        services: 'Services',
        projects: 'Projects',
    },
    fr: {
        about: 'À propos',
        contact: 'Contact',
        services: 'Services',
        projects: 'Projets',
    },
};

export function Header({ id, locale, onMenu, small, top }: {
    id: string,
    locale: string,
    onMenu: (id: string) => void,
    small: boolean,
    top: boolean,
}) {
    const t = (k: string) => i18n[locale][k] || '';

    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <ThemeProvider theme={createTheme({
            components: {
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            backgroundColor: small ? 'transparent' : top ? 'transparent' : theme.palette.background.default,
                            padding: top ? '1rem 1rem 4rem 1rem' : '1rem 1rem 1rem 1rem',
                            transition: 'all 425ms',
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            color: top ? theme.palette.common.white : theme.palette.text.primary,
                            fontWeight: 300,
                        },
                    },
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            color: top ? theme.palette.common.white : theme.palette.text.primary,
                        },
                    },
                },
                MuiSwitch: {
                    styleOverrides: {
                        switchBase: {
                            color: top ? theme.palette.common.white : theme.palette.text.primary,
                        },
                        track: {
                            backgroundColor: theme.palette.text.primary,
                        },
                    },
                },
            },
            palette: {
                action: {
                    disabled: theme.palette.grey[500],
                },
                divider: theme.palette.grey[500],
            },
        })}>
            <AppBar elevation={small ? 0 : top ? 0 : 4} id={id} position={small ? 'absolute' : 'fixed'}>
                <Box display="flex" flexDirection="row" margin="auto" maxWidth="lg" pb={2} width="100%" >
                    <Button disableRipple={true} onClick={() => onMenu('top')} variant="text">
                        <Logo />
                    </Button>
                    <Box alignItems="flex-end" display="flex" flex="1" flexDirection="column">
                        <Box display="flex" gap={2} justifyContent="flex-end">
                            <I18nChooser />
                            <DarkModeSwitch />
                        </Box>
                        <Box display="flex" flex="1" justifyContent="flex-end">
                            <Box display={small ? 'none' : 'flex'} gap={4}>
                                <Button onClick={() => onMenu('about')} variant="text">
                                    {t('about')}
                                </Button>
                                <Button onClick={() => onMenu('services')} variant="text">
                                    {t('services')}
                                </Button>
                                <Button onClick={() => onMenu('projects')} variant="text">
                                    {t('projects')}
                                </Button>
                                <Button onClick={() => onMenu('contact')} variant="text">
                                    {t('contact')}
                                </Button>
                            </Box>
                        </Box>
                        <Box display={small ? 'flex' : 'none'}>
                            <IconButton
                                id="menu-button"
                                aria-controls={open ? 'menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                {open ? (<CloseIcon />) : (<OpenIcon />)}
                            </IconButton>
                            <Menu
                                id="menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                sx={{
                                    '.MuiPaper-root': {
                                        backgroundColor: theme.palette.background.default,
                                        color: theme.palette.text.primary,
                                    },
                                }}
                            >
                                <MenuItem onClick={() => { onMenu('about'); handleClose(); }}>
                                    {t('about')}
                                </MenuItem>
                                <MenuItem onClick={() => { onMenu('services'); handleClose(); }}>
                                    {t('services')}
                                </MenuItem>
                                <MenuItem onClick={() => { onMenu('projects'); handleClose(); }}>
                                    {t('projects')}
                                </MenuItem>
                                <MenuItem onClick={() => { onMenu('contact'); handleClose(); }}>
                                    {t('contact')}
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Box>
            </AppBar>
        </ThemeProvider >
    );
}