import { createContext, ReactNode, useMemo, useState } from 'react';
import { Locale, Locales } from './locale';

const localeIdKey = 'localeId';

export interface I18nContextData {
    id: string;
    locale: Locale;
    locales: Locales,
    setLocale: (locale: Locale) => void;
}

export const I18nContext = createContext<I18nContextData | undefined>(undefined);

export function I18n({
    children,
    locales,
}: {
    children: ReactNode,
    locales: Locales,
}) {
    const browserLocaleIds = navigator.languages as string[] || [navigator.language];
    const storageLocaleId = localStorage.getItem(localeIdKey);

    let defLocale: Locale | undefined;

    if (typeof storageLocaleId === 'string')
        defLocale = locales.find((l) => l.id === storageLocaleId);

    if (defLocale === undefined)
        for (let i = 0; i < browserLocaleIds.length; i++) {
            defLocale = locales.find((l) => l.id === browserLocaleIds[i]);
            if (defLocale !== undefined) break;
        }

    if (defLocale === undefined)
        for (let i = 0; i < browserLocaleIds.length; i++) {
            const id = browserLocaleIds[i].split(/[-_]/)[0];
            defLocale = locales.find((l) => l.id === id);
            if (defLocale !== undefined) break;
        }

    if (defLocale === undefined)
        defLocale = locales[0];

    localStorage.setItem(localeIdKey, defLocale.id)

    const [currentLocale, setLocale] = useState(defLocale!);

    const locale = useMemo<I18nContextData>(
        () => ({
            id: currentLocale.id,
            locale: currentLocale,
            locales: locales,
            setLocale: (locale: Locale) => {
                setLocale(locale);
                localStorage.setItem(localeIdKey, locale.id);
            },
        }),
        [currentLocale, locales],
    );

    return (
        <I18nContext.Provider value={locale}>
            {children}
        </I18nContext.Provider>
    );
}
