import { Switch } from '@mui/material';
import { Brightness4 as DarkModeIcon, Brightness5 as LightModeIcon } from '@mui/icons-material';
import { DarkModeContext } from './dark-mode';

export function DarkModeSwitch() {
    return (
        <DarkModeContext.Consumer>
            {(mode) => (
                <Switch
                    checked={mode?.active}
                    checkedIcon={<DarkModeIcon fontSize='small'/>}
                    color="default"
                    icon={<LightModeIcon fontSize='small'/>}
                    onChange={() => mode?.toggle()}
                />
            )}
        </DarkModeContext.Consumer>
    );
};