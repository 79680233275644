import { ChangeEvent, useState } from 'react';
import { Box, BoxProps, Button, Checkbox, Fade, FormControlLabel, Snackbar, TextField, Typography } from '@mui/material';
import isEmail from 'validator/lib/isEmail';

const i18n: { [key: string]: { [key: string]: string } } = {
    en: {
        dataConsent: 'I authorize the processing of my personal data provided in the contact form above in order to respond to my request and any subsequent correspondence',
        emailAddress: 'Email address',
        emailAddressRequired: 'email address is required',
        firstName: 'First name',
        firstNameRequired: 'first name is required',
        lastName: 'Last name',
        lastNameRequired: 'last name is required',
        message: 'Message',
        messageError: 'An error occurred while sending your message, please try again',
        messageRequired: 'a message is required',
        messageSent: 'Your message has been sent, we will contact you as soon as possible',
        phoneNumber: 'Phone number',
        reset: 'Reset the form',
        submit: "Submit",
        text: 'Would you like to discuss a new product, a partnership or something else, please fill out the following form and we will get back to you as soon as possible.',
        title: 'Request information',
    },
    fr: {
        dataConsent: 'J\'autorise le traitement de mes données personnelles fournies dans le formulaire de contact ci-dessus afin de répondre à ma demande et à toute correspondance ultérieure',
        emailAddress: 'Adresse e-mail',
        emailAddressRequired: 'l\'adresse e-mail est requise',
        firstName: 'Prénom',
        firstNameRequired: 'le prénom est requis',
        lastName: 'Nom de famille',
        lastNameRequired: 'le nom de famille est requis',
        message: 'Message',
        messageError: 'Une erreur s\'est produite lors de l\'envoi de votre message, veuillez réessayer',
        messageRequired: 'un message est requis',
        messageSent: 'Votre message a été envoyé, nous vous contacterons dans les plus brefs délais',
        phoneNumber: 'Numéro de téléphone',
        reset: 'Réinitialiser le formulaire',
        submit: "Envoyer",
        text: 'Vous souhaitez discuter d\'un nouveau produit, d\'un partenariat ou d\'autre chose, veuillez remplir le formulaire suivant et nous vous répondrons dans les plus brefs délais.',
        title: 'Demande d\'informations',
    },
};

export function Contact(props: { locale: string, small: boolean } & BoxProps) {
    const [locale, small] = [props.locale, props.small];

    const [submitMessage, setSubmitMessage] = useState('');

    const t = (key: string): string => i18n[locale][key] || '';

    const newForm = () => ({
        firstName: {
            error: false,
            value: '',
            touched: false,
        },
        lastName: {
            error: false,
            value: '',
            touched: false,
        },
        email: {
            error: false,
            value: '',
            touched: false,
        },
        phone: {
            value: '',
            touched: false,
        },
        message: {
            error: false,
            value: '',
            touched: false,
        },
        dataConsent: {
            value: true,
        },
        touched: false,
        valid: false,
    });

    const [form, setForm] = useState(newForm());

    const validateForm = () => {
        form.firstName.error = !form.firstName.value.length;
        form.lastName.error = !form.lastName.value.length;
        form.email.error = !form.email.value.length || !isEmail(form.email.value);
        form.message.error = !form.email.value.length;

        form.touched = true;

        form.valid = [form.firstName, form.lastName, form.email, form.message].every((f) => !f.error);

        setForm(Object.assign({}, form));
    }

    const handler = {
        firstName(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            form.firstName.value = e.target.value.trimStart();
            form.firstName.touched = true;
            validateForm();
        },
        lastName(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            form.lastName.value = e.target.value.trimStart();
            form.lastName.touched = true;
            validateForm();
        },
        email(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            form.email.value = e.target.value.trimStart();
            form.email.touched = true;
            validateForm();
        },
        phone(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            form.phone.value = e.target.value.trimStart();
            form.phone.touched = true;
            validateForm();
        },
        message(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            form.message.value = e.target.value.trimStart();
            form.message.touched = true;
            validateForm();
        },
        dataConsent(e: ChangeEvent<HTMLInputElement>) {
            form.dataConsent.value = e.target.checked;
            validateForm();
        },
    };

    const submit = async () => {
        if (!form.valid) return;

        const data = {
            locale,
            firstName: form.firstName.value,
            lastName: form.lastName.value,
            email: form.email.value,
            phone: form.phone.value,
            message: form.message.value,
            dataConsent: form.dataConsent.value,
        };

        const res = await fetch('/api/contact', { method: 'POST', body: JSON.stringify(data) });

        if (res.status === 202) {
            reset();
            setSubmitMessage(t('messageSent'));

        } else {
            setSubmitMessage(t('messageError'));
        }


        setTimeout(() => setSubmitMessage(''), 6000);
    }

    const reset = () => setForm(newForm());

    return (
        <Box margin="auto" maxWidth="lg" width="100%" {...props}>
            <Box m={2}>
                <Typography color="primary.dark" my={4} textAlign={small ? 'center' : 'start'} variant="h3">
                    {t('title')}
                </Typography>
                <Typography align="justify" variant="body1">
                    {t('text')}
                </Typography>
                <Box my={4}>
                    <TextField
                        color="secondary"
                        error={form.firstName.touched && form.firstName.error}
                        fullWidth
                        helperText={form.firstName.touched && form.firstName.error ? t('firstNameRequired') : ''}
                        id="firstName"
                        label={t('firstName')}
                        margin="normal"
                        onBlur={handler.firstName}
                        onChange={handler.firstName}
                        type="text"
                        value={form.firstName.value}
                        required
                    />
                    <TextField
                        color="secondary"
                        error={form.lastName.touched && form.lastName.error}
                        fullWidth
                        helperText={form.lastName.touched && form.lastName.error ? t('lastNameRequired') : ''}
                        id="lastName"
                        label={t('lastName')}
                        margin="normal"
                        onBlur={handler.lastName}
                        onChange={handler.lastName}
                        type="text"
                        value={form.lastName.value}
                        required
                    />
                    <TextField
                        color="secondary"
                        error={form.email.touched && form.email.error}
                        fullWidth
                        helperText={form.email.touched && form.email.error ? t('emailAddressRequired') : ''}
                        id="email"
                        label={t('emailAddress')}
                        margin="normal"
                        onBlur={handler.email}
                        onChange={handler.email}
                        type="email"
                        value={form.email.value}
                        required
                    />
                    <TextField
                        color="secondary"
                        fullWidth
                        id="phone"
                        label={t('phoneNumber')}
                        margin="normal"
                        onChange={handler.phone}
                        type="tel"
                        value={form.phone.value}
                    />
                    <TextField
                        color="secondary"
                        error={form.message.touched && form.message.error}
                        fullWidth
                        helperText={form.message.touched && form.message.error ? t('messageRequired') : ''}
                        id="message"
                        label={t('message')}
                        margin="normal"
                        minRows={5}
                        multiline
                        onBlur={handler.message}
                        onChange={handler.message}
                        type="message"
                        value={form.message.value}
                        required
                    />
                    <Box my={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.dataConsent.value}
                                    color="secondary"
                                    id="dataConsent"
                                    onChange={handler.dataConsent}
                                />
                            }
                            label={t('dataConsent')}
                        />
                    </Box>
                    <Box alignItems="center" display="flex" flexDirection="column" gap={2} my={4}>
                        <Button color="secondary" disabled={!form.valid} onClick={submit} variant="contained">
                            {t('submit')}
                        </Button>
                        <Fade in={form.touched}>
                            <Button color="secondary" onClick={reset} size="small" variant="text">
                                {t('reset')}
                            </Button>
                        </Fade>
                    </Box>
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={!!submitMessage}
                message={submitMessage}
            />
        </Box>
    );
}