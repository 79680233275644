import { Box, Typography } from '@mui/material';

export function Terms({
    businessCountry,
    businessEmail,
    businessName,
    locale,
    showTrademarks,
}: {
    businessCountry: string;
    businessEmail: string;
    businessName: string;
    locale: string;
    showTrademarks?: boolean; // default false
}) {

    const i18n: { [key: string]: { [key: string]: string } } = {
        en: {
            close: `Close`,
            title: `Terms of Use`,
            introP1: `These Terms of Use ("Terms") are a legal contract between you and/or the entity or company that you represent ("you" or "your") and ${businessName} ("we" or "us"), and govern your use of all the text, data, information, software, graphics, and other content (collectively "Content") that we and our affiliates may make available to you.`,
            introP2: `Read these terms carefully before browsing this website. Using this website indicates that you have both read and accept these terms. You cannot use this website if you do not accept these terms.`,
            changesTitle: `Changes`,
            changesP1: `We may modify, suspend or discontinue this website and/or the Content at any time and without notifying you. We may also change, update, add or remove provisions of these Terms from time to time.`,
            communicationsTitle: `Electronic communications`,
            communicationsP1: `By using our website, you consent to receive electronic communications from us. These electronic communications may include notices and other information concerning or related to the website. These electronic communications are part of your relationship with us.`,
            copyrightTitle: `Copyright`,
            copyrightP1: `Use of any part of or content from this website for commercial purposes of any kind is expressly prohibited. To request permission to use any of the content on this website for commercial purposes, please contact our company at ${businessEmail}.`,
            copyrightP2: `Under no circumstances may any of the content on this website be displayed on either a commercial or personal website without obtaining prior permission. Unauthorized use of any information from this website may violate copyright, trademark and other laws.`,
            trademarksTitle: `Trademarks`,
            trademarksP1: `${businessName} is a registered trademark. All other product and company names used or mentioned herein are or may be trademarks™ or registered® trademarks of their respective holders. Use of them as such does not imply any affiliation with or endorsement by them.`,
            disclaimerTitle: `Disclaimer`,
            disclaimerP1: `This website and all information contained herein is provided "as is", without warranty of any kind, either express or implied, including but not limited to any implied warranty of merchantability or fitness for a particular purpose.`,
            disclaimerP2: `The content, texts, documents, and related illustrations published on this website could include technical inaccuracies or typographical mistakes. ${businessName} may make changes and/or enhancements in the content of this website at any time, without notification.`,
            disclaimerP3: `Without limiting the generality of the foregoing, we make no warranty that your use of our websites will be uninterrupted, timely, secure, or error free or that defects will be corrected. No advice or information, whether oral or written, obtained by you through this website or from us or our subsidiaries/other affiliated companies shall create any warranty. We disclaim all equitable indemnities.`,
            linksTitle: `Links to third party sites`,
            linksP1A: `Our provision of a link to any other site or location is for your convenience only and does not signify our endorsement of any such other site or location, or its contents.`,
            linksP1B: `${businessName.toUpperCase()} WILL NOT ACCEPT LIABILITY FOR ANY INFORMATION, SOFTWARE, OR LINKS FOUND AT ANY OTHER WEBSITE, INTERNET LOCATION, OR SOURCE OF INFORMATION, OR FOR ANY USE OF SUCH INFORMATION.`,
            liabilityTitle: `Limitation of liability`,
            liabilityP1: `In no event shall ${businessName} be liable for any direct, indirect, special, or consequential damages, or any other damages whatsoever, for any use of or reliance on this website, or any linked or referenced website, including, without limitation, any lost opportunity, lost profits, business interruption, loss of programs or data, even if expressly advised or otherwise aware of the possibility of such damages, whether in an action of contract, negligence, tort, or otherwise.`,
            applicableLawsTitle: `Applicable laws`,
            applicableLawsP1: `By using this website, you agree that the laws of ${businessName}, without regard to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise between you and ${businessName}.`,
            localLawsTitle: `Local laws`,
            localLawsP1: `We control and operate the website from our headquarter in ${businessCountry}, and the entirety of the website may not be appropriate or available for use in other locations. If you use our websites outside ${businessCountry}, you are solely responsible for following applicable local laws.`,
            contactTitle: `Contact us`,
            contactP1: `If you have any questions about these Terms or otherwise need to contact us for any reason, you can reach us at ${businessEmail}.`,
        },
        fr: {
            close: `Fermer`,
            title: `Conditions d'utilisation`,
            introP1: `Ces conditions d'utilisation ("Conditions") sont un contrat juridique entre vous et/ou l'entité ou l'entreprise que vous représentez ("vous" ou "votre") et ${businessName} ("nous" ou "nous") et régir votre utilisation de tous les textes, données, informations, logiciels, graphiques et autres contenus (collectivement "Contenu") que nous et nos affiliés pouvons mettre à votre disposition.`,
            introP2: `Lisez attentivement ces conditions avant de naviguer sur ce site Web. L'utilisation de ce site Web indique que vous avez à la fois lu et accepté ces conditions. Vous ne pouvez pas utiliser ce site Web si vous n'acceptez pas ces conditions.`,
            changesTitle: `Modifications`,
            changesP1: `Nous pouvons modifier, suspendre ou interrompre ce site Web et/ou le contenu à tout moment et sans vous en informer. Nous pouvons également modifier, mettre à jour, ajouter ou supprimer des dispositions de ces Conditions de temps à autre.`,
            communicationsTitle: `Communications électroniques`,
            communicationsP1: `En utilisant notre site Web, vous acceptez de recevoir des communications électroniques de notre part. Ces communications électroniques peuvent inclure des avis et d'autres informations concernant ou liées au site Web. Ces communications électroniques font partie de votre relation avec nous.`,
            copyrightTitle: `Droits d'auteur`,
            copyrightP1: `L'utilisation de toute partie ou du contenu de ce site Web à des fins commerciales de quelque nature que ce soit est expressément interdite. Pour demander l'autorisation d'utiliser tout contenu de ce site Web à des fins commerciales, veuillez contacter notre société au ${businessEmail}.`,
            copyrightP2: `En aucun cas, le contenu de ce site Web ne peut être affiché sur un site Web commercial ou personnel sans autorisation préalable. L'utilisation non autorisée de toute information de ce site Web peut violer les droits d'auteur, les marques de commerce et d'autres lois.`,
            trademarksTitle: `Marques de commerce`,
            trademarksP1: `${businessName} est une marque déposée. Tous les autres noms de produits et de sociétés utilisés ou mentionnés ici sont ou peuvent être des marques™ ou des marques déposées® de leurs détenteurs respectifs. Leur utilisation en tant que tels n'implique aucune affiliation ou approbation de leur part.`,
            disclaimerTitle: 'Avertissement',
            disclaimerP1: `Ce site Web et toutes les informations qu'il contient sont fournis "tels quels", sans garantie d'aucune sorte, expresse ou implicite, y compris, mais sans s'y limiter, toute garantie implicite de qualité marchande ou d'adéquation à un usage particulier.`,
            disclaimerP2: `Le contenu, les textes, les documents et les illustrations connexes publiés sur ce site Web peuvent inclure des inexactitudes techniques ou des erreurs typographiques. ${businessName} peut apporter des modifications et/ou des améliorations au contenu de ce site Web à tout moment, sans notification.`,
            disclaimerP3: `Sans limiter la généralité de ce qui précède, nous ne garantissons pas que votre utilisation de nos sites Web sera ininterrompue, opportune, sécurisée ou sans erreur ou que les défauts seront corrigés. Aucun conseil ou information, oral ou écrit, obtenu par vous via ce site Web ou de nous ou de nos filiales/autres sociétés affiliées ne créera aucune garantie. Nous déclinons toute indemnité équitable.`,
            linksTitle: `Liens vers des sites tiers`,
            linksP1A: `Notre disposition d'un lien vers tout autre site ou emplacement est pour votre commodité uniquement et ne signifie pas notre approbation de tout autre site ou emplacement, ou de son contenu.`,
            linksP1B: `${businessName.toUpperCase()} N'ACCEPTERA PAS LA RESPONSABILITÉ POUR TOUTE INFORMATION, LOGICIEL OU LIEN TROUVÉ SUR TOUT AUTRE SITE WEB, EMPLACEMENT INTERNET OU SOURCE D'INFORMATIONS, OU POUR TOUTE UTILISATION DE CES INFORMATIONS.`,
            liabilityTitle: `Limitation de responsabilité`,
            liabilityP1: `En aucun cas, ${businessName} ne pourra être tenu responsable de tout dommage direct, indirect, spécial ou consécutif, ou de tout autre dommage de quelque nature que ce soit, pour toute utilisation ou confiance en ce site Web, ou tout site Web lié ou référencé, y compris, sans limitation , toute occasion manquée, perte de profits, interruption d'activité, perte de programmes ou de données, même si expressément conseillé ou autrement conscient de la possibilité de tels dommages, que ce soit dans une action contractuelle, de négligence, de délit ou autre.`,
            applicableLawsTitle: `Lois applicables`,
            applicableLawsP1: `En utilisant ce site Web, vous acceptez que les lois du ${businessCountry}, sans égard aux principes de conflit de lois, régiront les présentes conditions d'utilisation et tout différend de quelque sorte que ce soit qui pourrait survenir entre vous et ${businessName}.`,
            localLawsTitle: `Lois locales`,
            localLawsP1: `Nous contrôlons et exploitons le site Web depuis notre siège social au ${businessName}, et l'intégralité du site Web peut ne pas être appropriée ou disponible pour une utilisation dans d'autres endroits. Si vous utilisez nos sites Web en dehors du ${businessCountry}, vous êtes seul responsable du respect des lois locales applicables.`,
            contactTitle: `Nous contacter`,
            contactP1: `Si vous avez des questions sur ces Conditions ou si vous avez besoin de nous contacter pour quelque raison que ce soit, vous pouvez nous joindre au ${businessEmail}.`,
        },
    };

    const t = (key: string): string => i18n[locale][key] || `no translations for ${key}`;

    return (
        <Box>
            <Typography align="justify" mb={2} variant="h5" > {t('title')}</Typography>

            <Typography align="justify" my={1} variant="body1">{t('introP1')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('introP2')}</Typography>

            <Typography align="justify" my={2} variant="h6">{t('changesTitle')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('changesP1')}</Typography>

            <Typography align="justify" my={2} variant="h6">{t('communicationsTitle')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('communicationsP1')}</Typography>

            <Typography align="justify" my={2} variant="h6">{t('copyrightTitle')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('copyrightP1')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('copyrightP2')}</Typography>

            {
                showTrademarks === true &&
                <Typography align="justify" my={2} variant="h6">{t('trademarksTitle')}</Typography> &&
                <Typography align="justify" mb={1} variant="body1">{t('trademarksP1')}</Typography>
            }

            <Typography align="justify" my={2} variant="h6">{t('disclaimerTitle')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('disclaimerP1')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('disclaimerP2')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('disclaimerP3')}</Typography>

            <Typography align="justify" my={2} variant="h6">{t('linksTitle')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('linksP1A')} {t('linksP1B').toUpperCase()}</Typography>

            <Typography align="justify" my={2} variant="h6">{t('liabilityTitle')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('liabilityP1')}</Typography>

            <Typography align="justify" my={2} variant="h6">{t('applicableLawsTitle')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('applicableLawsP1')}</Typography>

            <Typography align="justify" my={2} variant="h6">{t('localLawsTitle')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('localLawsP1')}</Typography>

            <Typography align="justify" my={2} variant="h6">{t('contactTitle')}</Typography>
            <Typography align="justify" mb={1} variant="body1">{t('contactP1')}</Typography>
        </Box>
    );
}