import { Box, Typography } from '@mui/material';

export function Policy({
    businessCity,
    businessCountry,
    businessName,
    businessPhone,
    businessStreet,
    businessState,
    businessUrl,
    businessZip,
    locale,
    showAddress,
    showWebsite,
    showGatheringPersonalInformation,
    showSecurity,
    showAdvertisements,
    showExternalLinks,
    showAdwords,
    showProtectionPersonalInformation,
    showAggregatedStatistics,
    showAffiliateDisclosure,
    showCookies,
    showEcommerce,
    showBusinessTransfers,
    showPolicyChanges,
}: {
    businessCity: string;
    businessCountry: string;
    businessName: string;
    businessPhone: string;
    businessStreet: string;
    businessState: string;
    businessUrl: string;
    businessZip: string;
    locale: string;
    showAddress?: boolean;                       // default false
    showWebsite?: boolean;                       // default true
    showGatheringPersonalInformation?: boolean;  // default true
    showSecurity?: boolean;                      // default true
    showAdvertisements?: boolean;                // default false
    showExternalLinks?: boolean;                 // default true
    showAdwords?: boolean;                       // default false
    showProtectionPersonalInformation?: boolean; // default true
    showAggregatedStatistics?: boolean;          // default true
    showAffiliateDisclosure?: boolean;           // default false
    showCookies?: boolean;                       // default false
    showEcommerce?: boolean;                     // default false
    showBusinessTransfers?: boolean;             // default true
    showPolicyChanges?: boolean;                 // default true
}) {

    const i18n: { [key: string]: { [key: string]: string } } = {
        en: {
            close: `Close`,
            title: `Privacy Policy`,
            subTitle: `Your privacy is critically important to us.`,
            addressTitle: `${businessName} is located at:`,
            introP1A: `It is ${businessName}'s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to`,
            introP1B: `${businessUrl}`,
            introP1C: `(hereinafter, "us", "we", or "${businessUrl}"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.`,
            introP2: `This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website.Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.`,
            websiteTitle: `Website Visitors`,
            websiteP1: `Like most website operators, ${businessName} collects non- personally - identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. ${businessName}'s purpose in collecting non-personally identifying information is to better understand how ${businessName}'s visitors use its website.From time to time, ${businessName} may release non- personally - identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.`,
            websiteP2: `${businessName} also collects potentially personally - identifying information like Internet Protocol(IP) addresses for logged in users and for users leaving comments on ${businessUrl} blog posts. ${businessName} only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally - identifying information as described below.`,
            gatheringTitle: `Gathering of Personally - Identifying Information`,
            gatheringP1: `Certain visitors to ${businessName}'s websites choose to interact with ${businessName} in ways that require ${businessName} to gather personally-identifying information. The amount and type of information that ${businessName} gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at ${businessUrl} to provide a username and email address.`,
            securityTitle: `Security`,
            securityP1: `The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100 % secure.While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.`,
            adsTitle: `Advertisements`,
            adsP1: `Ads appearing on our website may be delivered to users by advertising partners, who may set cookies.These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer.This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you.This Privacy Policy covers the use of cookies by ${businessName} and does not cover the use of cookies by any advertisers.`,
            linksTitle: `Links to External Sites`,
            linksP1: `Our Service may contain links to external sites that are not operated by us.If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.`,
            linksP2: `We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.`,
            adwordsTitle: `${businessUrl} uses Google AdWords for remarketing`,
            adwordsP1: `${businessUrl} uses the remarketing services to advertise on third party websites (including Google) to previous visitors to our site. It could mean that we advertise to previous visitors who haven't completed a task on our site, for example using the contact form to make an enquiry. This could be in the form of an advertisement on the Google search results page, or a site in the Google Display Network. Third-party vendors, including Google, use cookies to serve ads based on someone's past visits.Of course, any data collected will be used in accordance with our own privacy policy and Google's privacy policy.`,
            adwordsP2: `You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you want to you can opt out of interest - based advertising entirely by cookie settings or permanently using a browser plugin.`,
            protectionTitle: `Protection of certain Personally - Identifying Information`,
            protectionP1: `${businessName} discloses potentially personally - identifying and personally - identifying information only to those of its employees, contractors and affiliated organizations that(i) need to know that information in order to process it on ${businessName}'s behalf or to provide services available at ${businessName}'s website, and(ii) that have agreed not to disclose it to others.Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using ${businessName}'s website, you consent to the transfer of such information to them. ${businessName} will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, ${businessName} discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when ${businessName} believes in good faith that disclosure is reasonably necessary to protect the property or rights of ${businessName}, third parties or the public at large.`,
            protectionP2: `If you are a registered user of ${businessUrl} and have supplied your email address, ${businessUrl} may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with ${businessName} and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. ${businessName} takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.`,
            statisticsTitle: `Aggregated Statistics`,
            statisticsP1: `${businessName} may collect statistics about the behavior of visitors to its website. ${businessName} may display this information publicly or provide it to others.However, ${businessName} does not disclose your personally - identifying information.`,
            disclosureTitle: `Affiliate Disclosure`,
            disclosureP1: `This site uses affiliate links and does earn a commission from certain links.This does not affect your purchases or the price you may pay.`,
            cookiesTitle: `Cookies`,
            cookiesP1: `To enrich and perfect your online experience, ${businessName} uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.`,
            cookiesP2: `A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. ${businessName} uses cookies to help ${businessName} identify and track visitors, their usage of ${businessUrl}, and their website access preferences. ${businessName} visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using ${businessName}'s websites, with the drawback that certain features of ${businessName}'s websites may not function properly without the aid of cookies.`,
            cookiesP3: `By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to ${businessName}'s use of cookies.`,
            ecommerceTitle: `E - commerce`,
            ecommerceP1: `Those who engage in transactions with ${businessName} – by purchasing ${businessName}'s services or products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, ${businessName} collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with ${businessName}. ${businessName} does not disclose personally - identifying information other than as described below.And visitors can always refuse to supply personally - identifying information, with the caveat that it may prevent them from engaging in certain website - related activities.`,
            transfersTitle: `Business Transfers`,
            transfersP1: `If ${businessName}, or substantially all of its assets, were acquired, or in the unlikely event that ${businessName} goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party.You acknowledge that such transfers may occur, and that any acquirer of ${businessName} may continue to use your personal information as set forth in this policy.`,
            changesTitle: `Privacy Policy Changes`,
            changesP1: `Although most changes are likely to be minor, ${businessName} may change its Privacy Policy from time to time, and in ${businessName}'s sole discretion. ${businessName} encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.`,
        },
        fr: {
            close: `Fermer`,
            title: `Politique de confidentialité`,
            subTitle: `Votre confidentialité est extrêmement importante pour nous.`,
            addressTitle: `${businessName} est située à:`,
            introP1A: `La politique de ${businessName} est de respecter votre vie privée concernant les informations que nous pouvons collecter lors de l'utilisation de notre site Web. Cette politique de confidentialité s'applique à`,
            introP1B: `${businessUrl}`,
            introP1C: `(ci-après, «nous» ou «${businessUrl}»). Nous respectons votre vie privée et nous nous engageons à protéger les informations personnellement identifiables que vous pouvez nous fournir via le site Web. Nous avons adopté cette politique de confidentialité («Politique de confidentialité») pour expliquer quelles informations peuvent être collectées sur notre site Web, comment nous utilisons ces informations et dans quelles circonstances nous pouvons divulguer ces informations à des tiers. Cette politique de confidentialité s'applique uniquement aux informations que nous collectons via le site Web et ne s'applique pas à notre collecte d'informations provenant d'autres sources.`,
            introP2: `Cette politique de confidentialité, ainsi que les conditions générales publiées sur notre site Web, énoncent les règles et politiques générales régissant votre utilisation de notre site Web. En fonction de vos activités lors de la visite de notre site Web, vous devrez peut-être accepter des conditions générales supplémentaires.`,
            websiteTitle: `Visiteurs du Site Web`,
            websiteP1: `Comme la plupart des exploitants de sites Web, ${businessName} collecte des informations non personnellement identifiables du type que les navigateurs Web et les serveurs mettent généralement à disposition, telles que le type de navigateur, la langue, le site référent et la date et l'heure de chaque demande de visiteur. Le but de ${businessName} dans la collecte d'informations non personnelles est de mieux comprendre comment les visiteurs de ${businessName} utilisent son site Web. De temps en temps, ${businessName} peut divulguer des informations non personnelles dans l'ensemble, par exemple, en publiant un rapport sur les tendances de l'utilisation de son site Web.`,
            websiteP2: `${businessName} recueille également des informations potentiellement personnelles telles que les adresses IP (Internet Protocol) pour les utilisateurs connectés et pour les utilisateurs qui laissent des commentaires sur les articles de blog ${businessUrl}. ${businessName} ne divulgue les adresses IP des utilisateurs et des commentateurs connectés que dans les mêmes circonstances qu'il utilise et divulgue les informations d'identification personnelle décrites ci-dessous.`,
            gatheringTitle: `Collecte d'Informations d'Identification Personnelle`,
            gatheringP1: `Certains visiteurs des sites Web de ${businessName} choisissent d'interagir avec ${businessName} d'une manière qui nécessite que ${businessName} collecte des informations d'identification personnelle. La quantité et le type d'informations que ${businessName} recueille dépendent de la nature de l'interaction. Par exemple, nous demandons aux visiteurs qui s'inscrivent à un blog à ${businessUrl} de fournir un nom d'utilisateur et une adresse e-mail.`,
            securityTitle: `Sécurité`,
            securityP1: `La sécurité de vos informations personnelles est importante pour nous, mais n'oubliez pas qu'aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est sûre à 100%. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos informations personnelles, nous ne pouvons pas garantir leur sécurité absolue.`,
            adsTitle: `Annonces`,
            adsP1: `Les publicités apparaissant sur notre site Web peuvent être transmises aux utilisateurs par des partenaires publicitaires, qui peuvent définir des «Cookies». Ces «Cookies» permettent au serveur publicitaire de reconnaître votre ordinateur chaque fois qu'il vous envoie une publicité en ligne pour compiler des informations sur vous ou sur les autres utilisateurs de votre ordinateur. Ces informations permettent notamment aux réseaux publicitaires de diffuser des publicités ciblées qui, selon eux, vous intéresseront le plus. Cette politique de confidentialité couvre l'utilisation des «Cookies» par ${businessName} et ne couvre pas l'utilisation des «Cookies» par les annonceurs.`,
            linksTitle: `Liens vers des Sites Externes`,
            linksP1: `Notre service peut contenir des liens vers des sites externes qui ne sont pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons fortement de consulter la politique de confidentialité et les termes et conditions de chaque site que vous visitez.`,
            linksP2: `Nous n'avons aucun contrôle sur, et n'assumons aucune responsabilité pour le contenu, les politiques de confidentialité ou les pratiques des sites, produits ou services tiers.`,
            adwordsTitle: `${businessUrl} utilise Google AdWords pour le remarketing`,
            adwordsP1: `${businessUrl} utilise les services de remarketing pour faire de la publicité sur des sites Web tiers (y compris Google) auprès des visiteurs précédents de notre site. Cela pourrait signifier que nous faisons de la publicité auprès de visiteurs précédents qui n'ont pas terminé une tâche sur notre site, par exemple en utilisant le formulaire de contact pour faire une demande. Cela peut prendre la forme d'une publicité sur la page de résultats de recherche Google ou d'un site du Réseau Display de Google. Les fournisseurs tiers, y compris Google, utilisent des «Cookies» pour diffuser des annonces en fonction des visites passées de quelqu'un. Bien entendu, toutes les données collectées seront utilisées conformément à notre propre politique de confidentialité et à la politique de confidentialité de Google.`,
            adwordsP2: `Vous pouvez définir des préférences sur la façon dont Google vous fait de la publicité à l'aide de la page Préférences de Google Ad.`,
            protectionTitle: `Protection de certaines informations d'identification personnelle`,
            protectionP1: `${businessName} ne divulgue des informations potentiellement identifiables et identifiant personnellement qu'à ceux de ses employés, sous-traitants et organisations affiliées qui (i) ont besoin de connaître ces informations afin de les traiter au nom de ${businessName} ou pour fournir des services disponibles sur le site Web de ${businessName}, et (ii) qui ont accepté de ne pas les divulguer à d'autres. Certains de ces employés, entrepreneurs et organisations affiliées peuvent être situés en dehors de votre pays d'origine; en utilisant le site Web de ${businessName}, vous consentez au transfert de ces informations. ${businessName} ne louera ni ne vendra à quiconque des informations susceptibles de vous identifier et de vous identifier personnellement. À l'exception de ses employés, sous-traitants et organisations affiliées, comme décrit ci-dessus, ${businessName} ne divulgue des informations d'identification personnelle et d'identification personnelle qu'en réponse à une assignation à comparaître, à une ordonnance d'un tribunal ou à une autre demande gouvernementale, ou lorsque ${businessName} croit de bonne foi que la divulgation est raisonnablement nécessaire pour protéger la propriété ou les droits de ${businessName}, de tiers ou du grand public.`,
            protectionP2: `Si vous êtes un utilisateur enregistré de ${businessUrl} et avez fourni votre adresse courriel, ${businessName} peut occasionnellement vous envoyer un courriel pour vous informer des nouvelles fonctionnalités, solliciter vos commentaires ou simplement vous tenir au courant de ce qui est en cours avec ${businessName} et nos produits. Nous utilisons principalement notre blog pour communiquer ce type d'informations, nous nous attendons donc à garder ce type de courrier électronique au minimum. Si vous nous envoyez une demande (par exemple via un e-mail d'assistance ou via l'un de nos mécanismes de rétroaction), nous nous réservons le droit de la publier afin de nous aider à clarifier ou à répondre à votre demande ou à nous aider à soutenir d'autres utilisateurs. ${businessName} prend toutes les mesures raisonnablement nécessaires pour se protéger contre l'accès, l'utilisation, la modification ou la destruction non autorisés d'informations susceptibles de vous identifier et de vous identifier personnellement.`,
            statisticsTitle: `Statistiques agrégées`,
            statisticsP1: `${businessName} peut collecter des statistiques sur le comportement des visiteurs de son site Web. ${businessName} peut afficher ces informations publiquement ou les fournir à d'autres. Cependant, ${businessName} ne divulgue pas vos informations d'identification personnelle.`,
            disclosureTitle: `Divulgation des affiliés`,
            disclosureP1: `Ce site utilise des liens d'affiliation et gagne une commission sur certains liens. Cela n'affecte pas vos achats ni le prix que vous pouvez payer.`,
            cookiesTitle: `«Cookies»`,
            cookiesP1: `Pour enrichir et perfectionner votre expérience en ligne, ${businessName} utilise des «Cookies», des technologies et services similaires fournis par d'autres pour afficher un contenu personnalisé, une publicité appropriée et enregistrer vos préférences sur votre ordinateur.`,
            cookiesP2: `Un «Cookie» est une chaîne d'informations qu'un site Web stocke sur l'ordinateur d'un visiteur et que le navigateur du visiteur fournit au site Web à chaque retour du visiteur. ${businessName} utilise des «Cookies» pour aider ${businessName} à identifier et suivre les visiteurs, leur utilisation de ${businessUrl} et leurs préférences d'accès au site Web. ${businessName} les visiteurs qui ne souhaitent pas que des «Cookies» soient placés sur leur ordinateur doivent configurer leur navigateur pour refuser les «Cookies» avant d'utiliser les sites Web de ${businessName}, avec l'inconvénient que certaines fonctionnalités des sites Web de ${businessName} peut ne pas fonctionner correctement sans l'aide de «Cookies».`,
            cookiesP3: `En continuant à naviguer sur notre site Web sans modifier vos paramètres de «Cookies», vous reconnaissez et acceptez par les présentes l'utilisation des «Cookies» par ${businessName}.`,
            ecommerceTitle: `Commerce électronique`,
            ecommerceP1: `Ceux qui effectuent des transactions avec ${businessName} - en achetant les services ou produits de ${businessName}, sont invités à fournir des informations supplémentaires, y compris, le cas échéant, les informations personnelles et financières nécessaires au traitement de ces transactions. Dans chaque cas, ${businessName} ne recueille ces informations que dans la mesure où cela est nécessaire ou approprié pour atteindre l'objectif de l'interaction du visiteur avec ${businessName}. ${businessName} ne divulgue pas d'informations d'identification personnelle autres que celles décrites ci-dessous. Et les visiteurs peuvent toujours refuser de fournir des informations d'identification personnelle, avec la réserve que cela peut les empêcher de s'engager dans certaines activités liées au site Web.`,
            transfersTitle: `Transferts d'entreprise`,
            transfersP1: `Si ${businessName}, ou la quasi-totalité de ses actifs, étaient acquis, ou dans le cas peu probable où ${businessName} ferait faillite ou ferait faillite, les informations utilisateur seraient l'un des actifs transférés ou acquis par une troisième tier. Vous reconnaissez que de tels transferts peuvent se produire et que tout acquéreur de ${businessName} peut continuer à utiliser vos informations personnelles comme indiqué dans cette politique.`,
            changesTitle: `Modifications de la politique de confidentialité`,
            changesP1: `Bien que la plupart des modifications soient probablement mineures, ${businessName} peut modifier sa politique de confidentialité de temps à autre, et à la seule discrétion de ${businessName}. ${businessName} encourage les visiteurs à consulter fréquemment cette page pour tout changement à sa politique de confidentialité. Votre utilisation continue de ce site après tout changement dans cette politique de confidentialité constituera votre acceptation d'un tel changement.`
        },
    };

    const t = (key: string): string => i18n[locale][key] || `no translations for ${key}`;

    return (
        <Box>
            <Typography align="justify" variant="h5" > {t('title')}</Typography>
            <Typography align="justify" mb={2} variant="subtitle1" > {t('subTitle')}</Typography>

            {
                showAddress === true && <>
                    <Typography align="justify" mb={1} variant="subtitle1">{t('addressTitle')}</Typography>
                    <Typography mb={2} ml={4}>
                        {businessStreet},&nbsp;{businessCity}<br />
                        {businessState},&nbsp;{businessCountry}&nbsp;&nbsp;{businessZip}<br />
                        {businessPhone}
                    </Typography>
                </>
            }

            <Typography align="justify" my={1} variant="body1">{t('introP1A')} <a href={t('introP1B')}>{t('introP1B')}</a></Typography>
            <Typography align="justify" mb={1} variant="body1">{t('introP2')}</Typography>

            {
                showWebsite !== false && <>
                    <Typography align="justify" my={2} variant="h6">{t('websiteTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('websiteP1')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('websiteP2')}</Typography>
                </>
            }

            {
                showGatheringPersonalInformation !== false && <>
                    <Typography align="justify" my={2} variant="h6">{t('gatheringTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('gatheringP1')}</Typography>
                </>
            }

            {
                showSecurity !== false && <>
                    <Typography align="justify" my={2} variant="h6">{t('securityTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('securityP1')}</Typography>
                </>
            }

            {
                showAdvertisements === true && <>
                    <Typography align="justify" my={2} variant="h6">{t('adsTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('adsP1')}</Typography>
                </>
            }

            {
                showExternalLinks !== false && <>
                    <Typography align="justify" my={2} variant="h6">{t('linksTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('linksP1')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('linksP2')}</Typography>
                </>
            }

            {
                showAdwords === true && <>
                    <Typography align="justify" my={2} variant="h6">{t('adwordsTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('adwordsP1')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('adwordsP2')}</Typography>
                </>
            }

            {
                showProtectionPersonalInformation !== false && <>
                    <Typography align="justify" my={2} variant="h6">{t('adwordsTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('adwordsP1')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('adwordsP2')}</Typography>
                </>
            }

            {
                showAggregatedStatistics !== false && <>
                    <Typography align="justify" my={2} variant="h6">{t('statisticsTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('statisticsP1')}</Typography>
                </>
            }

            {
                showAffiliateDisclosure === true && <>
                    <Typography align="justify" my={2} variant="h6">{t('disclosureTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('disclosureP1')}</Typography>
                </>
            }

            {
                showCookies === true && <>
                    <Typography align="justify" my={2} variant="h6">{t('cookiesTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('cookiesP1')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('cookiesP2')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('cookiesP3')}</Typography>
                </>
            }

            {
                showEcommerce === true && <>
                    <Typography align="justify" my={2} variant="h6">{t('ecommerceTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('ecommerceP1')}</Typography>
                </>
            }

            {
                showBusinessTransfers !== false && <>
                    <Typography align="justify" my={2} variant="h6">{t('transfersTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('transfersP1')}</Typography>
                </>
            }

            {
                showPolicyChanges !== false && <>
                    <Typography align="justify" my={2} variant="h6">{t('changesTitle')}</Typography>
                    <Typography align="justify" mb={1} variant="body1">{t('changesP1')}</Typography>
                </>
            }
        </Box>
    );
}