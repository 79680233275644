import { Box, BoxProps, Button, List, ListItem, Typography } from "@mui/material";
import { Bullet } from './bullet';
import { ReactComponent as GithubIconEn } from './github-en.svg';
import { ReactComponent as GithubIconFr } from './github-fr.svg';
import rcScannerImage from './rc-scanner.png';

const i18n: { [key: string]: { [key: string]: string } } = {
    en: {
        bullet1: 'Real time audio',
        bullet2: 'Real time control',
        bullet3: 'Minimum latency',
        bullet4: 'Minimum data',
        text: 'Rc Scanner is a working prototype that is both a server and a progressive web interface that allows you to remotely control your radio scanner and receive its audio from anywhere in the world, while maintaining the actual layout of the controls of the scanner.',
        title: 'Rc Scanner',
    },
    fr: {
        bullet1: 'Audio en temps réel',
        bullet2: 'Contrôle en temps réel',
        bullet3: 'Latence minimale',
        bullet4: 'Données minimales',
        text: 'Rc Scanner est un prototype fonctionnel qui est à la fois un serveur et une interface web progressive qui vous permet de contrôler à distance votre scanner radio et de recevoir son audio de n\'importe où dans le monde, tout en conservant la disposition réelle des commandes du scanner.',
        title: 'Rc Scanner',
    },
};

export function RcScanner(props: { locale: string, small: boolean } & BoxProps) {
    const [locale, small] = [props.locale, props.small];

    const t = (key: string): string => i18n[locale][key] || '';

    return (
        <Box {...props}>
            <Box mb={4}>
                <Typography color="secondary" my={4} textAlign={small ? 'center' : 'start'} variant="h4">
                    {t('title')}
                </Typography>
                <Typography align="justify" variant="body1">
                    {t('text')}
                </Typography>
            </Box>
            <Box alignItems="center" display="flex" flexDirection="row">
                <Box alignItems="center" display="flex" flexDirection="column">
                    <img src={rcScannerImage} alt="Rc Scanner" style={{ borderRadius: '8px' }} />
                    <Typography align="center" variant="caption">
                        Uniden BCD436HP
                    </Typography>
                </Box>
                <List>
                    {Object.keys(i18n[locale]).filter((key) => key.startsWith('bullet')).map((key) => (
                        <ListItem key={key}>
                            <Box alignItems="center" display="flex" flexDirection="row" gap="8px">
                                <Bullet />
                                <Typography variant="body1">
                                    {t(key)}
                                </Typography>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box alignItems="center" display="flex" flexDirection="column" my={4}>
                <Button color="inherit" href="https://github.com/chuot/rc-scanner/" sx={{ p: 0 }} target="_blank">
                    {locale === 'en' ? <GithubIconEn /> : locale === 'fr' ? <GithubIconFr /> : 'GitHub'}
                </Button>
            </Box>
        </Box>
    );
}