import { createTheme, responsiveFontSizes } from '@mui/material';
import { lightBlue, orange } from '@mui/material/colors';

export const lightTheme = responsiveFontSizes(createTheme({
  palette: {
    primary: Object.assign({}, lightBlue, { main: lightBlue['A700'] }),
    secondary: Object.assign({}, orange, { main: orange['A700'] }),
  },
  typography: {
    htmlFontSize: 14,
  },
}));