import { Box, BoxProps, Grid, Typography } from '@mui/material';
import { PandaTimer } from './panda-timer';
import { RcScanner } from './rc-scanner';
import { RdioScanner } from './rdio-scanner';

const i18n: { [key: string]: { [key: string]: string } } = {
    en: {
        title: 'Some of our projects',
        text: 'We are committed to open source software, we actively contribute to the community where we share our knowledge and our passion. Here are some of our community projects in which we are involved.',
    },
    fr: {
        title: 'Quelques projets',
        text: 'Nous sommes engagés dans les logiciels à code ouvert, nous contribuons activement à la communauté où nous partageons nos connaissances et notre passion. Voici quelques-uns de nos projets communautaires dans lesquels nous sommes impliqués.',
    },
};

export function Projects(props: { locale: string, small: boolean } & BoxProps) {
    const [locale, small] = [props.locale, props.small];

    const t = (key: string): string => i18n[locale][key] || '';

    return (
        <Box margin="auto" maxWidth="lg" width="100%" {...props}>
            <Box m={2}>
                <Typography color="primary.dark" my={4} textAlign={small ? 'center' : 'start'} variant="h3">
                    {t('title')}
                </Typography>
                <Typography align="justify" variant="body1">
                    {t('text')}
                </Typography>
                <Grid container columns={2} spacing={8}>
                    <Grid item xs={small ? 2 : 2}>
                        <RdioScanner locale={locale} small={small} />
                    </Grid>
                    <Grid item xs={small ? 2 : 1}>
                        <RcScanner locale={locale} small={small} />
                    </Grid>
                    <Grid item xs={small ? 2 : 1}>
                        <PandaTimer locale={locale} small={small} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}