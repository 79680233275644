import { useEffect, useState } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import landingImage from './landing.jpg';

const i18n: { [key: string]: string[] } = {
    en: [
        "Looking for good devs?",
        "Great, we are passionate devs!",
        "We love creating amazing new apps.",
        "So much that we code for fun."
    ],
    fr: [
        "Vous cherchez de bons développeurs?",
        "Super, car nous sommes des développeurs passionnés.",
        "Nous adorons créer de nouvelles applications.",
        "Tellement que nous codons pour le plaisir."
    ],
};

enum ttyMode {
    erase,
    type,
}

export function Landing(props: { locale: string, small: boolean } & BoxProps) {
    const [locale, small] = [props.locale, props.small];

    const [tty, setTTY] = useState<{
        index: number,
        mode: ttyMode,
        text: string,
    }>({
        index: 0,
        mode: ttyMode.type,
        text: '',
    });

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (tty.mode === ttyMode.type)
            if (tty.text.length < i18n[locale][tty.index].length)
                timeout = setTimeout(() => requestAnimationFrame(() => setTTY({
                    index: tty.index,
                    mode: ttyMode.type,
                    text: i18n[locale][tty.index].substring(0, tty.text.length + 1),
                })), 50);

            else
                timeout = setTimeout(() => requestAnimationFrame(() => setTTY({
                    index: tty.index,
                    mode: ttyMode.erase,
                    text: tty.text,
                })), 4000);

        else if (tty.mode === ttyMode.erase)
            if (tty.text.length > 0)
                timeout = setTimeout(() => requestAnimationFrame(() => setTTY({
                    index: tty.index,
                    mode: ttyMode.erase,
                    text: i18n[locale][tty.index].substring(0, tty.text.length - 1),
                })), 10);

            else
                timeout = setTimeout(() => requestAnimationFrame(() => setTTY({
                    index: tty.index < i18n[locale].length - 1 ? tty.index + 1 : 0,
                    mode: ttyMode.type,
                    text: '',
                })), 1000);

        return () => clearTimeout(timeout);
    });

    return (
        <Box {...props}>
            <Box sx={{
                alignItems: 'center',
                backgroundImage: `url(${landingImage})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                height: small ? '100vh' : '69vh',
                justifyContent: 'flex-end',
            }}>
                <Box sx={{
                    backgroundColor: '#000',
                    borderRadius: '1rem',
                    margin: small ? '1rem 1rem 6rem 1rem' : '1rem 1rem 2rem 1rem',
                }}>
                    <Typography
                        sx={{
                            color: 'rgba(255, 255, 255, 0.7)',
                            fontWeight: 300,
                            padding: '0.5rem',
                            whiteSpace: 'break-spaces',
                            '&::after': {
                                animationName: 'blink',
                                animationDuration: '0.5s',
                                animationFillMode: 'forwards',
                                animationIterationCount: 'infinite',
                                content: '"▉"',
                            },
                            '@keyframes blink': {
                                '0%, 24%,7 6%, 100%': {
                                    opacity: 0.5,
                                },
                                '25%, 75%': {
                                    opacity: 0,
                                },
                            },
                        }}
                        variant="h3">
                        {tty.text}
                    </Typography>
                </Box>
            </Box >
            <Box sx={{
                backgroundColor: 'secondary.main',
                height: '5px',
            }} />
        </Box>
    );
}
