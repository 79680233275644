import { useEffect } from 'react';
import { Box, BoxProps, Button, Typography } from "@mui/material";
import { ReactComponent as GithubIconEn } from './github-en.svg';
import { ReactComponent as GithubIconFr } from './github-fr.svg';
import { PandaTimer as PandaTimerApp } from './panda-timer-app';

const i18n: { [key: string]: { [key: string]: string } } = {
    en: {
        text1: 'The Panda Timer is a simple tool inspired by the logo of an Attention Deficit Support Organization. The objective of this tool, in addition to being very useful, is to make this organization and its services better known to the public.',
        text2: 'Try it, move the red slider around the timer.',
        title: 'The Panda Timer',
    },
    fr: {
        text1: 'Le Minuteur Panda est un outil simple inspiré du logo d\'une organisation de soutien au déficit d\'attention. L\'objectif de cet outil, en plus d\'être très utile, est de mieux faire connaître cette organisation et ses services au public.',
        text2: 'Essayez-le, déplacez le curseur rouge autour du minuteur.',
        title: 'Le Minuteur Panda',
    },
};
export function PandaTimer(props: { locale: string, small: boolean } & BoxProps) {
    const [locale, small] = [props.locale, props.small];

    const t = (key: string): string => i18n[locale][key] || '';

    useEffect(() => {
        const timer = new PandaTimerApp(undefined, {
            color: {
                background: 'transparent',
            },
            timeLeft: 20 * 60,
        });

        return () => timer.stop();
    }, [small]);

    return (
        <Box {...props}>
            <Typography color="secondary" my={4} textAlign={small ? 'center' : 'start'} variant="h4">
                {t('title')}
            </Typography>
            <Typography align="justify" mb={2} variant="body1">
                {t('text1')}
            </Typography>
            <Typography align="justify" mb={4} variant="body1">
                {t('text2')}
            </Typography>
            <Box alignItems="center" display="flex" justifyContent="center" minHeight="300px">
                <canvas id="panda-timer" height="300" width="300" />
            </Box>
            <Box alignItems="center" display="flex" flexDirection="column" my={4}>
                <Button color="inherit" href="https://github.com/chuot/panda-timer/" sx={{ p: 0 }} target="_blank">
                    {locale === 'en' ? <GithubIconEn /> : locale === 'fr' ? <GithubIconFr /> : 'GitHub'}
                </Button>
            </Box>
        </Box>
    );
}