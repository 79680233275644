import { createContext, ReactNode, RefObject, useEffect, useRef, useState } from 'react';

export interface ScrollerContextData {
    x: number;
    y: number;
}

function isScrollable(el: Element) {
    const hasScrollableContent = el.scrollHeight > el.clientHeight;

    const overflowYStyle = window.getComputedStyle(el).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

    return hasScrollableContent && !isOverflowHidden;
};

function getScrollableParent(el: Element | undefined): any {
    return !el || el === document.body
        ? document.body.parentNode
        : isScrollable(el)
            ? el
            : getScrollableParent(el.parentNode as Element);
};

function getScroll(el?: Element): ScrollerContextData {
    const parent = getScrollableParent(el);
    return {
        x: parent.scrollLeft,
        y: parent.scrollTop,
    }
}

export const ScrollerContext = createContext<ScrollerContextData>(getScroll());

export function Scroller({ children }: { children: ReactNode }) {
    const ref = useRef<Element>();
    const [scroll, setScroll] = useState(getScroll(ref.current));

    useEffect(() => {
        const scrollListener = () => setScroll(getScroll(ref.current));

        window.addEventListener('scroll', scrollListener);

        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    })

    return (
        <div ref={ref as RefObject<any>}>
            <ScrollerContext.Provider value={scroll}>
                {children}
            </ScrollerContext.Provider>
        </div>
    );
}